<template>
	<div class="deactivate-gamification-modal">
		<FeedbackModalPlay
			:isLoading="saveLoading"
			id="deactiveGamificationModal"
			:content="$t('gamification.deactivate_gamification_desc')"
			:title="$t('gamification.deactivate_gamification_warning')"
			noCloseOnEsc
			noCloseOnBackdrop
		>
			<template slot="actions">
				<div class="d-flex flex-column align-items-center w-100 mt-2">
					<ButtonPlay
						class="w-100 mb-2"
						@click="$bvModal.hide('deactiveGamificationModal')"
						:text="$t('gamification.keep_gamification')"
						icon="cup"
						iconType="bold"
						type="normal"
					/>
					<ButtonPlay
						class="w-100 mb-2"
						@click="deactivateGamification"
						:text="$t('gamification.deactivate_gamification')"
						type="feedback-error"
						icon="trash"
					/>
					<ButtonPlay
						class="w-100"
						@click="exludeGamification"
						:text="$t('gamification.delete_gamification')"
						type="feedback-error"
						icon="trash"
					/>
				</div>
			</template>
		</FeedbackModalPlay>

		<FeedbackModalPlay
			id="deactiveFeedbackModal"
			:isLoading="saveLoading"
			:status="deactivateFeedbackStatus"
			:title="deactivateFeedbackTitle"
			:content="deactivateFeedbackContent"
			noCloseOnEsc
			noCloseOnBackdrop
			@close="$router.push({ name: 'gamification' })"
		/>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	data() {
		return {
			saveLoading: false,
			deactivateFeedbackStatus: "",
			deactivateFeedbackContent: "",
			deactivateFeedbackTitle: "",
		};
	},
	methods: {
		...mapActions({
			actionSaveSettings: "config/actionSaveSettings",
			deleteGamification: "gamification/deleteGamification",
		}),
		async deactivateGamification() {
			this.saveLoading = true;

			try {
				await this.actionSaveSettings({
					id: "gamefication",
					value: "off",
				});
				this.deactivateFeedbackStatus = "success";
				this.deactivateFeedbackTitle = this.$t("gamification.deactivate_feedback_title");
				this.deactivateFeedbackContent = this.$t("gamification.deactivate_feedback_content");
			} catch (error) {
				this.deactivateFeedbackStatus = "error";
				this.deactivateFeedbackTitle = this.$t("gamification.error_to_deactivate");
				this.deactivateFeedbackContent = this.$t("gamification.error_to_deactivate_desc");
			} finally {
				this.saveLoading = false;
				this.$bvModal.hide("deactiveGamificationModal");
				this.$bvModal.show("deactiveFeedbackModal");
			}
		},
		async exludeGamification() {
			this.saveLoading = true;

			try {
				await this.deleteGamification();
				this.deactivateFeedbackStatus = "success";
				this.deactivateFeedbackTitle = this.$t("gamification.delete_feedback_title");
				this.deactivateFeedbackContent = this.$t("gamification.delete_feedback_content");
			} catch (error) {
				this.deactivateFeedbackStatus = "error";
				this.deactivateFeedbackTitle = this.$t("gamification.error_to_delete");
				this.deactivateFeedbackContent = this.$t("gamification.error_to_delete_desc");
			} finally {
				this.saveLoading = false;
				this.$bvModal.hide("deactiveGamificationModal");
				this.$bvModal.show("deactiveFeedbackModal");
			}
		},
	},
};
</script>
