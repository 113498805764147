<template>
	<Layout>
		<GamificationConfigTemplate />
	</Layout>
</template>

<script>
import GamificationConfigTemplate from "@/components/GamificationConfigTemplate.vue";

export default {
	components: {
		GamificationConfigTemplate,
	},
};
</script>
