<template>
	<div>
		<div class="gamification_content-tab_medals_description mb-5 col-12 col-md-9 p-0">
			<TextPlay
				weight="bold"
				color="var(--fontcolor)"
				:text="$t('gamification.medals_configuration')"
			/>
			<TextPlay
				color="var(--text-color)"
				class="mb-4"
				:text="$t('gamification.medals_configuration_description')"
			/>
			<TextPlay
				color="var(--text-color)"
				class="mb-3"
				:text="$t('gamification.medals_configuration_tip')"
			/>
		</div>

		<div class="gamification_content-tab_medals_config d-flex flex-column col-12 col-md-10 p-0">
			<MedalConfiguration
				v-for="(medal, index) in medalListWithUUID"
				:skeleton="loadFecth"
				:key="medal.uuid"
				:medal="medal"
				@change="handleMedalChange(index, $event)"
				@delete="handleMedalDelete(index)"
			/>
		</div>

		<div class="gamification_content-tab_medals_action-buttons d-flex flex-column mb-5 mb-md-0 p-0">
			<ButtonPlay
				@click="addMedal"
				class="mb-3 mt-4"
				type="gray"
				icon="add"
				w="240px"
				:text="$t('gamification.add_medal')"
			/>

			<TextPlay
				color="var(--text-color)"
				class="mt-4 mb-4"
				v-html="
					isMedalsSaved ? $t('gamification.activated_medals_disclaimer') : $t('gamification.activation_medals_tip')
				"
			/>

			<ButtonPlay
				type="normal"
				@click="saveMedals"
				iconRight="arrow-right-1"
				:w="isMedalsSaved ? '150px' : '240px'"
				v-if="!loadFecth"
				:text="isMedalsSaved ? $t('gamification.save') : $t('gamification.save_medals')"
			/>
		</div>

		<FeedbackModalPlay
			id="medals-configuration-feedback"
			:isLoading="saveMedalsLoading"
			:status="fdbkSaveMedalsStatus"
			:title="fdbkSaveMedalsTitle"
			:content="fdbkSaveMedalsContent"
			:confirmBtnText="fdbkSaveMedalsConfirmBtnText"
			:confirmBtnIcon="fdbkSaveMedalsConfirmBtnIcon"
			:confirmBtnIconType="fdbkSaveMedalsConfirmBtnIconType"
			@confirm="$router.push({ name: 'gamification' })"
		/>
	</div>
</template>

<script>
import MedalConfiguration from "@/components/MedalConfiguration.vue";
import notify from "@/services/libs/notification-play";
import { mapActions, mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";

export default {
	components: {
		MedalConfiguration,
	},
	data() {
		return {
			loadFecth: false,
			medalList: [{}, {}, {}, {}, {}],
			isMedalsSaved: false,
			saveMedalsLoading: false,
			fdbkSaveMedalsStatus: "",
			fdbkSaveMedalsTitle: "",
			fdbkSaveMedalsContent: "",
			fdbkSaveMedalsConfirmBtnText: "",
			fdbkSaveMedalsConfirmBtnIcon: "",
			fdbkSaveMedalsConfirmBtnIconType: "",
		};
	},
	computed: {
		...mapGetters({
			getMedals: "gamification/getMedals",
		}),
		medalListWithUUID() {
			return this.medalList.map(medal => {
				return {
					...medal,
					uuid: this.uuidv4(),
				};
			});
		},
	},
	methods: {
		...mapActions({
			updateMedals: "gamification/updateMedals",
			fetchMedals: "gamification/fetchMedals",
			fetchEmblems: "gamification/fetchEmblems",
		}),
		uuidv4,
		handleMedalChange(index, medal) {
			this.medalList[index] = medal;
		},
		handleMedalDelete(index) {
			this.medalList.splice(index, 1);
		},
		canAddMedal() {
			const medalsLimitItems = 10;
			if (this.medalList.length >= medalsLimitItems) {
				notify("error", this.$t("gamification.error_to_config_medals"), this.$t("gamification.max_medals"));
				return false;
			}
			return true;
		},
		addMedal() {
			if (!this.canAddMedal()) return;
			this.medalList.push({});
		},
		async isValidated() {
			for (const medal of this.medalList) {
				if (medal.name || medal.points || medal.emblem) {
					if (!medal.name || !medal.points || !medal.emblem) {
						notify(
							"error",
							this.$t("gamification.error_to_config_medals"),
							this.$t("gamification.you_need_to_set_a_medal")
						);
						return false;
					}
				}
			}

			let existsMedal = false;
			for (const medal of this.medalList) {
				if (medal.name && medal.points && medal.emblem) {
					existsMedal = true;
				}
			}

			if (existsMedal) {
				await this.$validator._base.reset();
				return true;
			}

			let isValid = await this.$validator._base.validateAll();
			if (isValid) return true;
			notify("error", this.$t("gamification.error_to_config_medals"), this.$t("gamification.you_need_to_set_a_medal"));
			return false;
		},
		async saveMedals() {
			if (!(await this.isValidated())) return;

			let data = this.medalList
				.filter(medal => medal.name && medal.points && medal.emblem)
				.map(medal => {
					return {
						id: medal?.id,
						emblem_id: medal.emblem?.id,
						name: medal.name,
						points: medal.points,
					};
				});

			this.$bvModal.show("medals-configuration-feedback");
			this.saveMedalsLoading = true;

			try {
				await this.updateMedals(data);
				this.medalList = await this.fetchMedals();
				this.isMedalsSaved = true;
				this.fdbkSaveMedalsStatus = "success";
				this.fdbkSaveMedalsTitle = this.$t("gamification.medals_feedback_title");
				this.fdbkSaveMedalsContent = this.$t("gamification.medals_feedback_content");
				this.fdbkSaveMedalsConfirmBtnText = this.$t("gamification.medals_feedback_btn");
				this.fdbkSaveMedalsConfirmBtnIcon = "tick";
				this.fdbkSaveMedalsConfirmBtnIconType = "outline";
			} catch (error) {
				this.fdbkSaveMedalsStatus = "error";
				this.fdbkSaveMedalsTitle = this.$t("gamification.error_to_save_medals");
				this.fdbkSaveMedalsContent = this.$t("gamification.error_to_save_medals_desc");
				this.fdbkSaveMedalsConfirmBtnText = "";
				this.fdbkSaveMedalsConfirmBtnIcon = "";
				this.fdbkSaveMedalsConfirmBtnIconType = "";
			} finally {
				this.saveMedalsLoading = false;
			}
		},
	},
	async mounted() {
		this.loadFecth = true;
		const medals = await this.fetchMedals();
		if (medals.length > 0) {
			this.medalList = medals;
			this.isMedalsSaved = true;
		}

		await this.fetchEmblems();
		this.loadFecth = false;
	},
};
</script>
