<template>
	<div class="medal-configuration d-flex mb-4 p-0">
		<div class="medal-configuration__inputs d-flex">
			<EmblemInput
				class="emblem-input"
				:emblem="emblem"
				@input="handleEmblemInput"
				:skeleton="skeleton"
			/>

			<InputFormPlay
				:placeholder="$t('gamification.medal_name')"
				:label="$t('gamification.give_medal_name')"
				:description="$t('gamification.medal_name_tip')"
				:validation="{ required: true, max: 30 }"
				maxlength="30"
				w="281px"
				@input="handleNameInput"
				:value="name"
				:skeleton="skeleton"
			/>

			<InputFormPlay
				:placeholder="$t('gamification.points_quantity')"
				:label="$t('gamification.points_to_give_medal')"
				:description="$t('gamification.points_to_give_medal_tip')"
				:validation="{ required: true, numeric: true, min: 1, max: 99999 }"
				:preventNonNumeric="true"
				:preventDots="true"
				:preventHyphens="true"
				:preventLetters="true"
				:preventSpaces="true"
				:disabled="!!id"
				type="number"
				min="1"
				max="99999"
				w="271px"
				@input="handlePointsInput"
				:value="points"
				:skeleton="skeleton"
			/>
		</div>

		<IconSax
			@click="handleDelete"
			v-if="!id && !skeleton"
			size="1.2rem"
			class="medal-configuration__delete-icon ml-4"
			name="trash"
			primary
		/>
	</div>
</template>

<script>
import EmblemInput from "./EmblemInput.vue";

export default {
	components: {
		EmblemInput,
	},
	props: {
		medal: { type: Object, default: () => ({}) },
		skeleton: { type: Boolean, default: false },
	},
	data() {
		return {
			emblem: null,
			name: "",
			points: "",
			id: undefined,
		};
	},
	watch: {
		medal: {
			handler() {
				this.emblem = this.medal.emblem;
				this.name = this.medal.name;
				this.points = this.medal.points;
				this.id = this.medal.id;
			},
			immediate: true,
		},
		emblem() {
			this.emitChange();
		},
		name() {
			this.emitChange();
		},
		points() {
			this.emitChange();
		},
	},
	methods: {
		handleEmblemInput(newEmblem) {
			this.emblem = newEmblem;
		},
		handleNameInput(newName) {
			this.name = newName;
		},
		handlePointsInput(newPoints) {
			this.points = newPoints;
		},
		handleDelete() {
			this.$emit("delete");
		},
		emitChange() {
			this.$emit("change", {
				id: this.id,
				emblem: this.emblem,
				name: this.name,
				points: this.points,
			});
		},
	},
};
</script>

<style lang="scss">
.medal-configuration {
	&__inputs {
		gap: 70px;

		.emblem-input {
			width: 171px;
		}
	}

	&__delete-icon {
		cursor: pointer;
	}
}

@media (max-width: 768px) {
	.medal-configuration {
		&__inputs {
			flex-direction: column;
			gap: 30px;
		}

		.emblem-input {
			width: 100%;
		}

		.input-form-play {
			width: 100% !important;
		}
	}
}
</style>
