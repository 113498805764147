<template>
	<div class="coins-config justify-content-between col-12 col-md-8 d-flex flex-column flex-md-row p-4">
		<div class="configuration d-flex flex-column col-12 col-md-6 p-0">
			<InputFormPlay
				:skeleton="isLoading"
				:label="$t('gamification.how_can_you_call_your_coins')"
				id="coin-name-input"
				class="mb-2"
				type="text"
				:placeholder="$t('gamification.give_coin_name')"
				:name="$t('gamification.coin_name')"
				:validation="{ required: true, alpha_num: true, min: 4, max: 15 }"
				preventSpaces
				:description="$t('gamification.coin_name_example')"
				@input="$emit('input', $event)"
				:value="coinName"
				maxlength="15"
			/>
		</div>

		<div class="tips-container d-flex flex-column align-items-center justify-content-center col-12 col-md-5">
			<div class="tips d-flex flex-column justify-content-center p-3">
				<TextPlay
					class="tips-title"
					color="var(--gamification-badge-color)"
					size="xxs"
					weight="semibold"
					:text="$t('gamification.coin_name_tips.title')"
				/>
				<ul class="pl-3 m-0 font-color">
					<li>
						<TextPlay
							size="xxs"
							:text="$t('gamification.coin_name_tips.character_limit')"
						/>
					</li>
					<li>
						<TextPlay
							size="xxs"
							:text="$t('gamification.coin_name_tips.not_numbers')"
						/>
					</li>
					<li>
						<TextPlay
							size="xxs"
							:text="$t('gamification.coin_name_tips.not_especial_characters')"
						/>
					</li>
					<li>
						<TextPlay
							size="xxs"
							:text="$t('gamification.coin_name_tips.not_spaces')"
						/>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		coinName: String,
		isLoading: Boolean,
	},
};
</script>

<style lang="scss" scoped>
.coins-config {
	border-radius: 0.6rem;
	background-color: var(--box-background-color);
	max-width: 675px;

	.tips {
		border-radius: 0.6rem;
		background-color: var(--caption-bg-color);

		li {
			color: var(--fontcolor);
		}
	}

	@media (max-width: 768px) {
		.tips-container {
			padding: 0;
			margin-top: 1rem;
		}
		.tips {
			width: 100%;
		}
	}
}
</style>
