<template>
	<div
		:class="{ 'is-invalid': isInvalid }"
		class="emblems-sidebar d-flex flex-column p-0"
	>
		<TextPlay
			class="emblem__label mb-2"
			color="var(--fontcolor)"
			weight="bold"
			size="sm"
			:text="$t('gamification.select_a_medal')"
			:skeleton="skeleton"
		/>
		<div class="emblem__actions d-flex align-items-center p-0">
			<Emblem
				class="emblem-image"
				v-b-toggle="id"
				:size="size"
				:src="emblem?.picture"
				:skeleton="skeleton"
			/>
			<input
				:name="id"
				type="text"
				v-validate="{ required: true }"
				v-model="inputState"
				hidden
			/>
			<IconSax
				v-b-toggle="id"
				name="arrow-down-1"
				size="1.2rem"
				class="emblem__select-icon p-2"
				primary
			/>
			<!-- <IconSax
				v-show="inputState"
				@click="handleDelete"
				name="trash"
				size="1rem"
				class="emblem__delete-icon p-2"
				primary
			/> -->
		</div>
		<EmblemsSidebar
			:id="id"
			@select="selectEmblem"
			v-if="!skeleton"
		/>
	</div>
</template>

<script>
import EmblemsSidebar from "@/components/EmblemsSidebar.vue";
import Emblem from "@/components/Emblem.vue";

export default {
	components: {
		EmblemsSidebar,
		Emblem,
	},
	data() {
		return {
			id: `emblem_actions_emblem-${this._uid}`,
			selectedEmblem: null,
			inputState: undefined,
		};
	},
	props: {
		emblem: {
			type: Object,
			default: () => ({}),
		},
		size: {
			type: String,
			default: "base",
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		selectEmblem(emblem) {
			this.selectedEmblem = emblem;
			this.$emit("input", emblem);
			this.inputState = emblem.picture;
		},
		handleDelete() {
			this.selectedEmblem = {};
			this.$emit("input", {});
			this.$emit("delete");
		},
	},
	computed: {
		isInvalid() {
			const field = this.veeFields[this.id];

			if (field?.validated && field?.invalid) {
				return true;
			}

			return false;
		},
	},
	updated() {
		this.inputState = this.emblem?.picture;
	},
};
</script>

<style lang="scss">
.emblems-sidebar {
	.medal-image {
		cursor: pointer;
	}
}

.is-invalid {
	.emblem-image {
		border: 1px solid var(--feedback-error-500) !important;
	}
}
</style>

<style lang="scss" scoped>
.isax {
	cursor: pointer;
}
</style>
