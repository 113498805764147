export default function notifyPlay(status, title, description) {
	const notificationBox = document.querySelector(".notification-box");
	let removeTimeout;

	function sanitizeStatus() {
		const statusMap = {
			sucesso: "success",
			erro: "error",
			success: "success",
			error: "error",
			warning: "warning",
			info: "info",
			gamification: "gamification",
		};

		return statusMap[status] || "default";
	}

	async function fetchSvg(iconPath) {
		try {
			const response = await fetch(iconPath);
			return await response.text();
		} catch (error) {
			return "";
		}
	}

	function getIcon() {
		const baseUrl = process.env.VUE_ASSETS_URL;

		const iconMap = {
			success: "/icons/iconsax/bold/tick-circle.svg",
			error: "/icons/iconsax/bold/close-circle.svg",
			warning: "/icons/iconsax/bold/info-circle.svg",
			info: "/icons/iconsax/bold/info-circle.svg",
			gamification: "/icons/iconsax/bold/star.svg",
			default: "/icons/iconsax/bold/information.svg",
		};

		return baseUrl + iconMap[sanitizeStatus()];
	}

	function removeNotification() {
		clearTimeout(removeTimeout);
		notificationBox.innerHTML = "";
	}

	async function showNotification() {
		const iconPath = getIcon();
		const svgIcon = await fetchSvg(iconPath);
		const status = sanitizeStatus();

		const htmlNotification = `
            <div class="notification-play notification-play--${status}">
                <div class="notification-play__icon">${svgIcon}</div>
                <div class="notification-play__container">
                    <h3 class="title">${title}</h3>
                    <span class="description">${description ?? ""}</span>
                </div>
            </div>
        `;

		notificationBox.innerHTML = htmlNotification;
		notificationBox.addEventListener("click", removeNotification);

		removeTimeout = setTimeout(() => {
			removeNotification();
		}, 6300);
	}

	removeNotification();

	showNotification();
}

export { notifyPlay };
