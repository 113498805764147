import { render, staticRenderFns } from "./CouponConfiguration.vue?vue&type=template&id=a0af9a40&"
var script = {}
import style0 from "./CouponConfiguration.vue?vue&type=style&index=0&id=a0af9a40&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports